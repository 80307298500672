import React from "react";
import {
  useNavigate
} from 'react-router-dom';


const Navbar = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className="navbar">
        <span onClick={() => {navigate("/about")}}>about</span>
        <span onClick={() => {navigate("/projects")}}>projects</span>
      </div>
    </>
  )
}

export default Navbar;