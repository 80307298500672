import './App.css';
import Navbar from './components/Navbar';
import About from './pages/About';
import Projects from './pages/Projects';
import {
  Routes,
  Route
} from 'react-router-dom';


function App() {
  return (<>
    <Navbar />
    <Routes>
      <Route path='/about' element={<About />} />
      <Route path='/projects' element={<Projects />} />
    </Routes>
    </>);
}

export default App;
